import React from "react";

const ComponentSpinner = () => {
  return (
    <div className="text-center">
    <span className="loading loading-spinner text-orange-500 loadingSize"></span>
    </div>
  );
};

export default ComponentSpinner;
