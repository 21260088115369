import(/* webpackMode: "eager" */ "/vercel/path0/components/single-use/all-projects-displayer/AllProjectsDisplayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/single-use/testimonial/TesimonialBody.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/kunal-chandra-das/Banner-Image.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/utils/type-writter/TypeWritter.tsx");
